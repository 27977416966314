import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default function TalkSvcBox(props) {
  const swiperRef = useRef(null);
  const [maxPageCount, setMaxPageCount] = useState(0);
  const [naviCount, setNaviCount] = useState(1);
  const [tabBtncnt, setTabBtncnt] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0, 0);
    }
    setMaxPageCount(Math.ceil(props.data.contents[0].contents.length / 4));
  }, []);

  useEffect(() => {
    setMaxPageCount(
      Math.ceil(props.data.contents[tabBtncnt].contents.length / 4)
    );
    setNaviCount(1);
  }, [tabBtncnt]);

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c, d) => {
    let moduleSubTitle;
    let contentTitle;
    let contentId;
    if (d === 'live') {
      moduleSubTitle = '실시간라이브';
      contentTitle = b.title;
      contentId = b.channelid;
    } else if (d === '24') {
      moduleSubTitle = '24t시간TV';
      contentTitle = b.title;
      contentId = b.channelid;
    } else {
      moduleSubTitle = '전체채널';
      contentTitle = '';
      contentId = '';
    }

    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음

    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '라이브', //모듈 아이디 없으면
        moduleTitle: '실시간라이브/24시간TV', //모듈 제목 (Require)
        moduleSubTitle: moduleSubTitle,
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: props.loc_idx, //페이지에서 해당 모듈의 위치
        moduleItemSeq: c, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 제목 (추가)
        contentId: contentId, //식별자
        contentTitle: contentTitle, //콘텐츠의 제목  (Require)
        contentType: 'L', //콘텐츠의 타입 (별도 표 참조)
      },
    });
  };

  const handleClick_custom = (a, b, c, d) => {
    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '톡모듈',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
      },
    });
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setNaviCount((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setNaviCount((prev) => prev - 1);
    }
  };

  return (
    <div className="mainContents-typeGray">
      <div className="contentsBlock_w playtalkBanner_w">
        <a
          href="https://www.sbs.co.kr/playtalk"
          onClick={(e) => handleClick_custom(e, '', '0', 'all')}
          className="playtalkBnrLink"
        >
          <strong className="iconSvg_playtalk">
            <i className="hide">playtalk</i>
          </strong>
          <span className="playtalkBnr-title">
            플레이톡 이야기의 즐거움을 잇다!
          </span>
          <span className="roundType-arrow">
            <i className="iconSvg_arrow"></i>
          </span>
        </a>
      </div>

      <div className="contentsBlock_w">
        <div className="tabBtns_w_playtalk">
          {props.data.contents.map((item, index) => (
            <a
              href="#none"
              onClick={() => {
                setTabBtncnt(index);
                if (swiperRef.current) {
                  swiperRef.current.slideTo(0, 0); // 스와이퍼를 첫 번째 슬라이드로 초기화
                  setNaviCount(1); // 네비게이션 카운트도 초기화
                }
              }}
              className={`tabBtn_typeText${
                tabBtncnt === index ? ' current' : ''
              }`}
            >
              <span className="tabBtn_text">{item.tab_name}</span>
            </a>
          ))}
        </div>

        <div className="listModule_w_playtalk">
          <button
            type="button"
            aria-label="다음 콘텐츠 보기"
            className={`btnTypeList-Next swiper-button-next${
              isAtEnd || maxPageCount <= 1 ? ' hidden' : ''
            }`}
            onClick={handleNext}
            disabled={isAtEnd}
            hidden={isAtEnd} // 여기에 hidden 속성 추가
          >
            <span className="iconSvg_arrow">
              <i className="hide">다음</i>
            </span>
            <span className="pagingNumber_w">
              <strong className="pagingNumber-now">{naviCount}</strong>/
              {maxPageCount}
            </span>
          </button>

          <Swiper
            ref={swiperRef}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={(swiper) => {
              setIsAtStart(swiper.isBeginning);
              setIsAtEnd(swiper.isEnd);
            }}
            slidesPerView={4}
            spaceBetween={24}
            slidesPerGroup={4}
            loop={false}
            pagination={{
              el: `.listModule_w_imgSeriesSquare .swiper-pagination`,
              clickable: false,
            }}
            navigation={{
              nextEl: `.listModule_w_imgSeriesSquare .swiper-button-next`,
              prevEl: `.listModule_w_imgSeriesSquare .swiper-button-prev`,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="listModuleSlider"
            wrapperClass="SliderContent"
          >
            {props.data.contents[tabBtncnt].contents.map((item, index) => {
              const handleOpenPopup = () => {
                window.__SBSLogModuleDataLayer.push({
                  type: 'click',
                  option: {
                    clickName: '플레이톡최상단타이틀',
                    clickType: 'NC',
                    value1: item.talk_title,
                    value2: item.talk_id,
                    value3: '',
                    value4: '',
                    value5: '',
                  },
                });
                window.open(
                  'https://www.sbs.co.kr/playtalk/module.html?code=' +
                    item.talk_id,
                  '타이틀명을 입력하시오',
                  'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no'
                );
              };
              return (
                <SwiperSlide>
                  <div className="listModuleItem swiper-slide">
                    <div
                      className={`modulePlaytalkWrap${
                        item.star_submit_yn === 'Y' ? ' playtalkStar-on' : ''
                      }${item.live_yn === 'Y' ? ' playtalkLive-on' : ''}`}
                    >
                      <div className="mpt_inner">
                        <a
                          onClick={handleOpenPopup}
                          href="#none"
                          className="mpt_link"
                        >
                          <div className="mpt_image_w">
                            <div className="mpt_image_inner">
                              <img
                                src={item.talk_img}
                                className="mpt_image"
                                alt={item.talk_title}
                              />
                            </div>
                          </div>
                          <strong className="mbt_title">
                            {item.talk_title}
                          </strong>
                        </a>
                        <div className="mpt_bottomBtn">
                          <a
                            onClick={handleOpenPopup}
                            href="#none"
                            className={`btnType-playtalk btnOpenType-playtalk${
                              item.wagel_yn === 'Y' ? ' aniType-playtalk' : ''
                            }`}
                          >
                            {/* <!-- 와글 : aniType-playtalk -->*/}
                            <span className="aniPlaytalk_w">
                              <span className="iconSvg_aniPlaytalk">
                                <i className="hide">와글</i>
                              </span>
                              <i className="iconSvg_shadow"></i>
                            </span>
                            <span className="iconSvg_playtalk"></span>
                            <strong className="btnType-title">플레이톡</strong>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="swiper-pagination"></div>
          <button
            type="button"
            aria-label="이전 콘텐츠 보기"
            className={`btnTypeList-Prev swiper-button-prev${
              isAtStart ? ' hidden' : ''
            }`}
            onClick={handlePrev}
            disabled={isAtStart}
          >
            <span className="iconSvg_arrow">
              <i className="hide">이전</i>
            </span>
            <span className="pagingNumber_w">
              <strong className="pagingNumber-now">{naviCount}</strong>/
              {maxPageCount}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
