import ko from 'axe-core/locales/ko.json';
import React, { useEffect, useState } from 'react';
import App from 'next/app';
import Head from 'next/head';
import { gnbId } from '../src/common/constants';
import gnbGenerater from '../src/common/services/gnbGenerater';
import FloatingBanner from '../src/component/m/main/banner/Floatingbanner';
import FloatingBanner_pc from '../src/component/pc/main/banner/Floatingbanner';

function MyApp({ router, Component, pageProps }) {

  const isDisplayGnb = pageProps.isSbsApp ? false : Array.isArray(router.query?.gnbYn) ? router.query?.gnbYn[0] !== 'N' : router.query?.gnbYn !== 'N';
  const isMobile = isMobilePathContain(router.pathname);
  // 메인 모바일 플로팅 배너 여부 
  const isMainMobileFloating = (pageProps.isMainMobileFloating !== undefined && pageProps.isMainMobileFloating) ? true : false;
  //메인 모바일 피씨 배너 여부
  const isMainPcFloating = (pageProps.isMainPcFloating !== undefined && pageProps.isMainPcFloating) ? true : false;

  useEffect(async () => {
    const lastPathSegment = (router.pathname || '').split('/').filter(x => !!x).pop();
    const render = { 'header': true, 'footer': true }

    if (pageProps.isSbsApp) {
      if (router.pathname.includes('/m/pass')) {
        render.header = false;
      }
    }

    await gnbGenerater({ isMobile, pathname: router.pathname, router, isDisplayGnb, render });

    // 핫이슈 페이지의 경우 페이지 새로고침시 스크롤 포지션을 유지 안하도록 처리
    if (window.history.scrollRestoration && lastPathSegment === 'hotissue') {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  

  return (
    <>
      <Head>
        <meta name='google-site-verification' content='A8FPDPyVe4prPCljbqy_eRyq3iQazgtIMtjwMHz8Em4' />
        <meta name='naver-site-verification' content='84018caf299c313821354bd9aec9f956228dd998' />
        <link href='/favicon.ico' type='image/x-icon' rel='shortcut icon' />
        <script type='text/javascript' src='/scripts/lib/ua-parser.min.js' />
        <script type='text/javascript' src='/scripts/lib/js.cookie.min.js' />
        <script type='text/javascript' src='/scripts/deviceDetector.min.js' />
      </Head>

      <ul id='skip_nav'>
        <li><a href='#header'>헤더 메뉴</a></li>
        <li><a href='#container'>본문컨텐츠</a></li>
        <li><a href='#footer'>풋터 메뉴</a></li>
      </ul>
      <div id='wrap' className={router.pathname.includes('/promotion') ? 'temp_simple_w' : (router.pathname.includes('/m/pass') && pageProps.isSbsApp) ? 'android_wrap' : 'sbs_common_wrap'}>
        {isMainMobileFloating && (<FloatingBanner {...pageProps.mainFloatingBannerData.contents[0]} />)}
        {isMainPcFloating && (<FloatingBanner_pc {...pageProps.mainFloatingBannerData.contents[0]} />)}
        <div id={gnbId.headerId} className='header_wrap' style={{ display: isDisplayGnb ? 'block' : 'none' }} />
        <Component {...pageProps} />
        <div id={gnbId.footerId} className='footer_wrap' style={{ display: isDisplayGnb ? 'block' : 'none' }} />
      </div>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const userAgent = await appContext.ctx.req ? appContext.ctx.req?.headers['user-agent'] : navigator.userAgent;

  appProps.pageProps.isSbsApp = await userAgent?.indexOf('SBSApp') > -1 ? true : false;
  appProps.pageProps.isAndroid = await userAgent?.indexOf('Android') > -1 ? true : false;

  return { ...appProps }
}

function getWrapClassName(router, pageProps) {
  if (router.pathname.includes('/promotion')) return 'temp_simple_w';
  if (router.pathname.includes('/m/pass') && pageProps.isAndroid && pageProps.isSbsApp) return 'android_wrap';
  return 'sbs_common_wrap';
}

function isMobilePathContain(path) {
  const mobilePathIndex = path.split('/').indexOf('m');
  return mobilePathIndex > -1 && mobilePathIndex < 3;
}

export default MyApp;