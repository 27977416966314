import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import Head from 'next/head';
import getMetaTagData from '../src/common/services/getMetaTagData';

import {
  deduplArray,
  deduplChannelArray,
  toTimeStamp,
} from '../src/common/utils/convertUtil';

import ReactSlider from 'react-slick';
import ContentsBox from '../src/component/pc/main/top/ContentsBox';
import EventBox from '../src/component/pc/main/top/EventBox';
import Free3Box from '../src/component/pc/main/top/Free3Box';
import Free4Box from '../src/component/pc/main/top/Free4Box';
import PgmBox from '../src/component/pc/main/top/PgmBox';

import ALLVODBox from '../src/component/pc/main/bottom/ALLVODBox';
import BANNERBox from '../src/component/pc/main/bottom/BANNERBox';
import CLIPBox from '../src/component/pc/main/bottom/CLIPBox';
import EVENTBox from '../src/component/pc/main/bottom/EVENTBox';
import FreeBox from '../src/component/pc/main/bottom/FREEBox';
import IMGFOURBox from '../src/component/pc/main/bottom/IMGFOURBox';
import IMGTHREEBox from '../src/component/pc/main/bottom/IMGTHREEBox';
import LiveBox from '../src/component/pc/main/bottom/LiveBox';
import MNEWSBox from '../src/component/pc/main/bottom/MNEWSBox';
import PGMBox from '../src/component/pc/main/bottom/PGMBox';
import PHOTOSTARBox from '../src/component/pc/main/bottom/PHOTOSTARBox';
import PNEWSBox from '../src/component/pc/main/bottom/PNEWSBox';
import POPCLIPBox from '../src/component/pc/main/bottom/POPCLIPBox';
import POPVODBox from '../src/component/pc/main/bottom/POPVODBox';
import TALKSVCBox from '../src/component/pc/main/bottom/TALKSVCBox';
import VodBox from '../src/component/pc/main/bottom/VodBox';
//광고추가
import AdBox from '../src/component/pc/main/bottom/AdBox';

//배너추가
import NoticePopupBanner from '../src/component/pc/main/banner/NoticePopupBanner';
import NoticeTopBanner from '../src/component/pc/main/banner/NoticeTopBanner';
import PLAYBANNERBox from '../src/component/pc/main/bottom/PLAYBANNERBox';

function Home({
  mainTopData,
  mainBottomData,
  metaTagData,
  mainFloatingBannerData,
  noticeData,
  jWTCheck,
}) {
  useEffect(() => {
    // //console.log('mainFloatingBannerData:', mainFloatingBannerData);
  }, [mainFloatingBannerData]); // This ensures the //console.log runs whenever mainFloatingBannerData changes

  let slickSliderRef = useRef(null);

  const [mainNoticeBannerData, setMainNoticeBannerData] = useState(null);
  const [topAutoButton, setTopAutoButton] = useState(1); //1 자동 시작 0 자동 멈춤

  const [relateContentIndex, setRelateContentIndex] = useState(0);
  const relateContentIndexStatus = { timeoutId: 0, changedTime: Date.now() };
  const childRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // const getTimeParam = () => {
  //   const now = new Date();
  //   const year = String(now.getFullYear()); // 4자리 년도
  //   const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1)
  //   const day = String(now.getDate()).padStart(2, '0'); // 일
  //   const hours = String(now.getHours()).padStart(2, '0');
  //
  //   return `${year}${month}${day}${hours}`;
  // };

  // 2depth 바꾸는 부분
  const onChangeMainTopFormationIndex = (index) => {
    const slaveSlideDelayTime = 200;
    // 0.2초 이내에 변경된 경우 슬라이딩 취소
    if (
      Date.now() - relateContentIndexStatus.changedTime <
      slaveSlideDelayTime
    ) {
      if (relateContentIndexStatus.timeoutId > 0) {
        clearTimeout(relateContentIndexStatus.timeoutId);
        relateContentIndexStatus.timeoutId = 0;
        relateContentIndexStatus.changedTime = Date.now();
      }
    }

    const timeoutId = setTimeout(() => {
      relateContentIndexStatus.changedTime = Date.now();
      setRelateContentIndex(index);
    }, slaveSlideDelayTime);
    relateContentIndexStatus.timeoutId = timeoutId;
  };

  const mainOnMouseOver = (e) => {
    childRef.current.mainOnMouseOver(e);
  };

  const mainOnMouseOut = (e) => {
    childRef.current.mainOnMouseOut(e);
  };

  useEffect(async () => {
    // setMainBottomData(await getMainBottomData());
    // setMainNoticeBannerData(await getMainNoticeBannerData());
    //TODO childmild slick 부분 테스트
  }, []);

  //TODO childmild 첫번째 슬릭 라인  추후 컴포넌트 쪼개고 이름 바꾸기
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    pauseOnHover: true,
    customPaging: function (i) {
      return (
        <>
          {i + 1} <div className="allCount"> / {mainTopData.items.length}</div>
        </>
      );
    },
    dotsClass: 'slick-dots notranslate',
  };

  //설명 팝업 닫는 함수
  function topAutoButtonChecking() {
    if (topAutoButton == 0) {
      setTopAutoButton(1);
      slickSliderRef.slickPlay();
    } else {
      setTopAutoButton(0);
      slickSliderRef.slickPause();
    }
  }

  return (
    <>
      <Head>
        <link
          key="css_pc_url"
          rel="stylesheet"
          href={`${process.env.NEXT_PUBLIC_GLOBAL_CSS_2023_PC_URL}?v=${process.env.NEXT_PUBLIC_BUILD_TIME}`}
        />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <script
          key="gnb_js_url"
          type="text/javascript"
          src={`${process.env.NEXT_PUBLIC_GNB_JS_URL}?time=20241206`} // 시간 파라미터 추가
        />
        <script
          src={process.env.URL_LIVE_JS_PLAYER_NEW}
          type="text/javascript"
        />

        <meta name="description" content={metaTagData.shareDescription} />
        <meta name="keywords" content={metaTagData.keywords} />
        <meta name="twitter:card" content="summary" />
        <noscript>Your browser does not support JavaScript!</noscript>
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:site_name" content="SBS" />
        <meta property="og:title" content={metaTagData.shareTitle || ''} />
        <meta
          property="og:description"
          content={metaTagData.shareDescription || ''}
        />
        <meta property="og:image" content={metaTagData.shareImageUrl || ''} />
        <meta property="og:url" content={metaTagData.currentUrl || ''} />
        <meta name="nate:site_name" content="SBS" />
        <meta name="nate:title" content={metaTagData.shareTitle || ''} />
        <meta
          name="nate:description"
          content={metaTagData.shareDescription || ''}
        />
        <meta name="nate:image" content={metaTagData.shareImageUrl || ''} />
        <meta name="nate:url" content={metaTagData.currentUrl || ''} />
        <title>{metaTagData.shareTitle}</title>
      </Head>

      <div id="container" className="contents_main">
        {/*{(mainFloatingBannerData != null && mainFloatingBannerData != '') ? <FloatingBanner data={mainFloatingBannerData.contents}></FloatingBanner> :*/}
        {/*    <></>}*/}

        {noticeData != null && noticeData.notice_top != '' ? (
          <NoticeTopBanner data={noticeData.notice_top}></NoticeTopBanner>
        ) : (
          <></>
        )}

        {noticeData != null && noticeData.notice_popup != '' ? (
          <NoticePopupBanner data={noticeData.notice_popup}></NoticePopupBanner>
        ) : (
          <></>
        )}
        {/* <!-- 편성 빅이벤트 배너 --> */}
        <div className="curationTopBanner_w">
          <img
            src="/images/curationBanner_0124.png"
            alt="즐거움이 이어지는 SBS play로 새로워졌어요! 플레이존에서 자세히 알아보기"
          />
        </div>

        {/* <!-- 상단 슬라이드 --> */}
        <div className="topSwiperWrap">
          {/* <!-- Swiper --> */}
          <div className="swiper mySwiper variable-width">
            {mainTopData != null ? (
              <ReactSlider
                ref={(slider) => (slickSliderRef = slider)}
                {...settings}
                afterChange={(index) => setCurrentSlide(index)}
              >
                {mainTopData.items.map((item, index) => {
                  if (item.type == 'PGM' && Array.isArray(item.contents)) {
                    //1 개통으로
                    for (let index = 0; index < item.contents.length; index++) {
                      return (
                        <PgmBox
                          key={'mt_' + index}
                          index={index}
                          currentSlide={currentSlide}
                          data={item.contents[index]}
                          clickParams="div=main_top_relate"
                        />
                      );
                    }
                  } else if (
                    item.type == 'CONTENTS' &&
                    Array.isArray(item.contents)
                  ) {
                    return (
                      <ContentsBox
                        key={'mt_' + index}
                        data={item}
                        currentSlide={currentSlide}
                        clickParams="div=main_top_relate"
                      />
                    );
                  } else if (
                    item.type == 'EVENT' &&
                    Array.isArray(item.contents)
                  ) {
                    //배너형 1개 이미지 박스
                    return (
                      <EventBox
                        key={'mt_' + index}
                        currentSlide={currentSlide}
                        data={item}
                        clickParams="div=main_top_relate"
                      />
                    );
                  } else if (
                    item.type == '3FREE' &&
                    Array.isArray(item.contents)
                  ) {
                    // 박스 3개짜리 이미지 api 에서 주는 순번대로 뿌린다  섞는 부분은 없어서 고정으로 가기로함
                    return (
                      <Free3Box
                        key={'mt_' + index}
                        data={item}
                        currentSlide={currentSlide}
                        clickParams="div=main_top_relate"
                      />
                    );
                  } else if (
                    item.type == '4FREE' &&
                    Array.isArray(item.contents)
                  ) {
                    // 박스 4개짜리 이미지 api 에서 주는 순번대로 뿌린다  섞는 부분은 없어서 고정으로 가기로함
                    return (
                      <Free4Box
                        key={'mt_' + index}
                        data={item}
                        currentSlide={currentSlide}
                        clickParams="div=main_top_relate"
                      />
                    );
                  }
                })}
              </ReactSlider>
            ) : (
              <div />
            )}
          </div>

          {/* 메인 탑영역 슬라이드 멈춤 영역 */}
          <div
            className={`slideAutoPlayControl${
              topAutoButton === 1 ? ' current' : ''
            }`}
          >
            <button
              type="button"
              className="btnSlideAutoPlay"
              onClick={() => topAutoButtonChecking()}
            >
              <i className="iconSvg_play"></i>
              <i className="iconSvg_pause"></i>
            </button>
          </div>
        </div>
        {/* 메인 탑영역 영역 끝 */}

        {/* 메인 하단영역 시작 */}
        {mainBottomData != null ? (
          <>
            {mainBottomData.items.map((item, index) => {
              let clickParamLink = '';
              try {
                if (item.list_code == 'pnews' && Array.isArray(item.contents)) {
                  clickParamLink = 'utm_source=sbsmain';
                } else {
                  clickParamLink = 'div=main_' + item.type; //TODO childmild == >> 논의는 필요해보임
                }
              } catch (error) {}

              if (
                item.type == 'LIVE' &&
                Array.isArray(item.contents1) &&
                Array.isArray(item.contents2)
              ) {
                return (
                  <LiveBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'PGM' && Array.isArray(item.contents)) {
                return (
                  <PGMBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'VOD' && Array.isArray(item.contents)) {
                return (
                  <VodBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'CLIP' && Array.isArray(item.contents)) {
                return (
                  <CLIPBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'IMG_THREE' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <IMGTHREEBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'IMG_FOUR' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <IMGFOURBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'POP_CLIP' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <POPCLIPBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'POP_VOD' &&
                Array.isArray(item.contents1) &&
                Array.isArray(item.contents2)
              ) {
                return (
                  <POPVODBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'TALK_SVC' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <TALKSVCBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'ALLVOD' &&
                Array.isArray(item.contents1) &&
                Array.isArray(item.contents2) &&
                Array.isArray(item.contents3)
              ) {
                //블랙형 allvod
                return (
                  <ALLVODBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'PHOTO_STAR' &&
                Array.isArray(item.content1) &&
                Array.isArray(item.content2) &&
                Array.isArray(item.content3)
              ) {
                //핑크 포토
                return (
                  <PHOTOSTARBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'BANNER' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <BANNERBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'PNEWS' && Array.isArray(item.contents)) {
                return (
                  <PNEWSBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (
                item.type == 'MAIN_NEWS' &&
                Array.isArray(item.contents)
              ) {
                return (
                  <MNEWSBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'EVENT' && Array.isArray(item.contents)) {
                return (
                  <EVENTBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'FREE' && Array.isArray(item.contents)) {
                return (
                  <FreeBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
                //2023년8월25일 광고 영역추가함 //광고면
              } else if (
                item.type == 'AD1' ||
                item.type == 'AD2' ||
                item.type == 'AD3'
              ) {
                return (
                  <AdBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              } else if (item.type == 'BRAND_CH') {
                return (
                  <PLAYBANNERBox
                    key={'mb_' + index}
                    loc_idx={index}
                    data={item}
                    clickParams={clickParamLink}
                  />
                );
              }
            })}
          </>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}

//TODO childmild ==> 구조상 이슈가 없으므로 통신 문은 그대로 사용 하고 신규 api 나오면 그걸로 호출하자

export async function getServerSideProps(ctx) {
  // header에서 meta teg에 넣어줄 데이터를 가져 오기 위함
  const { pageSection, pageCategory } = ctx.query;
  const { cookies } = ctx.req;
  const { LOGIN_JWT } = cookies;
  let isMainPcFloating = 'N';

  const jWTCheck = !!LOGIN_JWT;
  const metaTagData = getMetaTagData(ctx.req.url, pageSection, pageCategory);

  const mainTopData = await getMainTopData();
  const mainBottomData = await getMainBottomData();
  const noticeData = await getNoticeData();
  const mainFloatingBannerData = await getMainFloatingBannerData();
  if (
    mainFloatingBannerData.contents != undefined &&
    mainFloatingBannerData.contents[0].pc_view_yn === 'Y'
  ) {
    isMainPcFloating = true;
  }

  return {
    props: {
      mainTopData,
      mainBottomData,
      metaTagData,
      mainFloatingBannerData,
      isMainPcFloating,
      noticeData,
      jWTCheck,
    },
  };
}

//메인 상단 영역 호출하는 api 부분
const getMainTopData = async () => {
  let result = [];

  try {
    const mainTopApiData = await Axios.get(
      process.env.NEXT_PUBLIC_API_MAIN_TOP,
      { timeout: 3000 }
    );
    result = mainTopApiData?.data;
  } catch (error) {
    console.error(error);
  }

  return result;
};

const getMainBottomData = async () => {
  let result = [];

  try {
    const mainBottomApiData = await Axios.get(
      process.env.NEXT_PUBLIC_API_MAIN_BOTTOM,
      { timeout: 3000 }
    );
    result = mainBottomApiData?.data;
  } catch (error) {
    console.error(error);
  }

  return result;
};

const getMainNoticeBannerData = async () => {
  let result = null;

  //console.log('NEXT_PUBLIC_URL_MAIN_NOTICE_BANNER_DATA :>> ', process.env.NEXT_PUBLIC_URL_MAIN_NOTICE_BANNER_DATA);

  try {
    const mainNoticeBannerData = await Axios.get(
      process.env.NEXT_PUBLIC_URL_MAIN_NOTICE_BANNER_DATA,
      { timeout: 3000 }
    );
    result = mainNoticeBannerData.data
      ? { ...mainNoticeBannerData.data }
      : null;

    if (result.pc) {
      result.deviceData = result.pc;
      delete result.pc;
      delete result.mobile;
    }
  } catch (error) {
    console.error(error);
    result = null;
  }

  return result;
};

const getMainFloatingBannerData = async () => {
  let result = [];

  //console.log('NEXT_PUBLIC_API_MAIN_MOBILE_FLOATING_BANNER :>> ', process.env.NEXT_PUBLIC_API_MAIN_MOBILE_FLOATING_BANNER);

  try {
    const mainFloatingBnnerData = await Axios.get(
      process.env.NEXT_PUBLIC_API_MAIN_MOBILE_FLOATING_BANNER,
      { timeout: 3000 }
    );
    result = mainFloatingBnnerData?.data;
  } catch (error) {
    console.error(error);
    result = null;
  }

  return result;
};

const getNoticeData = async () => {
  let result = [];

  //console.log('NEXT_PUBLIC_API_MAIN_NOTICE :>> ', process.env.NEXT_PUBLIC_API_MAIN_NOTICE);

  try {
    const noticeApiData = await Axios.get(
      process.env.NEXT_PUBLIC_API_MAIN_NOTICE,
      { timeout: 3000 }
    );
    result = noticeApiData?.data;
  } catch (error) {
    console.error(error);
  }

  return result;
};

export default Home;
