export default function sectionInfo(sectionId) {
  const sectionInfo = {
    tv: {
      title: '',
      pcLink: '/tv',
      mLink: '/m/tv',
      gnbHeaderType: 'normal',
      gnbSection: 'tv',
    },
    live: {
      title: '온에어',
      pcLink: '/live',
      mLink: '/m/live',
      gnbHeaderType: 'normal',
      gnbSection: 'live',
    },
    news: {
      title: '뉴스홈',
      pcLink: 'https://news.sbs.co.kr',
      mLink: 'https://mnews.sbs.co.kr',
      gnbHeaderType: 'main',
      gnbSection: 'news',
    },
    radio: {
      title: '라디오',
      pcLink: '/radio',
      mLink: '/m/radio',
      gnbHeaderType: 'main',
      gnbSection: 'radio',
    },
    star: {
      title: 'STAR',
      pcLink: 'https://star.sbs.co.kr',
      mLink: 'https://m.star.sbs.co.kr',
      gnbHeaderType: 'normal',
      gnbSection: 'star',
    },
    event: {
      title: '이벤트',
      pcLink: '/event',
      mLink: '/m/event',
      gnbHeaderType: 'main',
      gnbSection: 'event',
      gnbSubSection: '',
    },
    hotissue: {
      title: '핫이슈',
      pcLink: '/hotissue',
      mLink: '/m/hotissue',
      gnbHeaderType: 'normal',
      gnbSection: 'hotissue',
    },
    pass: {
      title: '이용권',
      pcLink: '/pass',
      mLink: '/m/pass',
      gnbHeaderType: 'simple',
      gnbMobileHeaderType: 'normal',
      gnbSection: '',
      gnbMobileSection: 'pass',
    },
    playzone: {
      title: '플레이존',
      pcLink: '/playzone',
      mLink: '/m/playzone',
      gnbHeaderType: 'main',
      gnbSection: 'playzone',
    },
  };

  let sectionData = sectionInfo[sectionId];
  // 섹션 리스트에 없는경우는 TV로

  if (!sectionData) {
    if (sectionId === 'promotion') {
      sectionData = { ...sectionInfo.event };
      // sectionData.gnbHeaderType = 'simple';

      sectionData = {
        title: '이벤트',
        pcLink: '/event',
        mLink: '/m/event',
        gnbHeaderType: 'main',
        gnbSection: 'event',
        gnbSubSection: '',
      };
    } else {
      sectionData = {
        gnbHeaderType: 'main',
        gnbSection: '',
        mLink: '/m',
        pcLink: '/',
        title: '',
      };
    }
  }

  return { sectionId, sectionData };
}
